import React from "react"
import SubscrptionForm from "../components/subscription-form.js"
import bookCover from "../content/assets/images/dev-guidebook-cover.png"

export default () => (
  <>
    <section className="lg:py-2">
      <div class="pt-8 overflow-hidden lg:mx-24 sm:pt-12 lg:relative lg:py-40">
        <div class="mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:grid-cols-2 lg:gap-24">
          <div>
            <h1>
              <span className="block text-sm font-semibold uppercase tracking-wide text-gray-500 sm:text-base lg:text-sm xl:text-base">
                Coming soon
              </span>
              <span className="mt-1 block text-3xl tracking-tight font-extrabold sm:text-4xl xl:text-5xl">
                <span className="block text-gray-900">The book you wish</span>
                <span className="block text-indigo-600">your manager had read</span>
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
               (and your team will be glad that you did) Understand what it takes to multiply the impact of everyone around you.
            </p>
            <div className="mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <p className="text-base font-medium text-gray-900">
                Sign up now to get free access to draft chapters.
              </p>
              <SubscrptionForm 
                  buttonText="Notify Me"
                  formUrl="https://app.convertkit.com/forms/1943417/subscriptions" 
              />
              <p className="mt-3 text-sm text-gray-500">
                You can easily unsubscribe at any time
              </p>
            </div>
          </div>
        </div>

        <div class="sm:mx-auto sm:max-w-3xl sm:px-6">
          <div class="py-12 sm:relative sm:mt-12 sm:py-16 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
            <div class="relative pl-4 lg:-mr-40 sm:mx-auto sm:max-w-3xl sm:px-0 lg:max-w-none lg:h-full lg:pl-12">
              <img
                class="w-full rounded-md shadow-xl ring-1 ring-black ring-opacity-5 lg:h-full lg:w-auto lg:max-w-none"
                src={bookCover}
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="lg:py-2">
      <div class="pt-8 bg-gray-50 overflow-hidden sm:pt-12 lg:relative lg:py-40">
          <div class="mx-auto max-w-md text-center px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl lg:grid lg:gap-2">
              <h1>
                  <div>
                      <span className="mt-1 text-4xl block tracking-tight font-extrabold sm:text-5xl">
                          <span className="block text-gray-900">Peek inside</span>
                          <span className="block text-indigo-600">table of contents</span>
                      </span>
                  </div>
              </h1>
              <ul className="mt-3 lg:mx-72 sm:max-w-3xl text-left text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  <li>
                    Chapter 1: Management Fundamentals
                    <ul className="ml-10 mb-5">
                        <li>1.1: Management defined</li>
                        <li>1.2: Engineering manager responsibilities</li>
                        <li>1.3: Authority and power explained</li>
                        <li>1.4: Management skills </li>
                        <li>1.5: Part of a system</li>
                    </ul>
                  </li> 
                  
                  <li>
                    Chapter 2: Transition Challenges
                    <ul className="ml-10 mb-5">
                        <li>Management is hard to describe</li>
                        <li>Management is not easy</li>
                        <li>You must change your mindset</li>
                        <li>Relationships will change</li>
                        <li>Decisions have more impact</li>
                        <li>The transition is a career change</li>
                    </ul>
                  </li> 
                  <li>
                    Chapter 3: Transition Preparation
                    <ul className="ml-10 mb-5">
                        <li>Setting the scene</li>
                        <li>Life as an individual contributor</li>
                        <li>Approaching the transition</li>
                        <li>Try before you buy</li>
                    </ul>
                  </li> 
                  <li>
                      Chapter 4: Line Management
                      <ul className="ml-10 mb-5">
                          <li>Line management responsibilities</li>
                          <li>Line management goals</li>
                          <li>Performance management</li>
                          <li>Career development</li>
                      </ul>
                  </li> 
                  <li>
                      Chapter 5: Culture
                      <ul className="ml-10 mb-5">
                          <li>What is culture</li>
                          <li>What's the effect of culture</li>
                          <li>Building team culture</li>
                          <li>Conflict management</li>
                          <li>Accountability</li>
                      </ul>
                  </li> 
                  <li>
                      Chapter 6: Growing People
                      <ul className="ml-10 mb-5">
                          <li>Coaching/Mentoring/Sponsorship</li>
                          <li>Accountability</li>
                          <li>Growth is uncomfortable</li>
                      </ul>
                  </li> 
                  <li>
                      Chapter 7: High Performing Teams
                      <ul className="ml-10 mb-5">
                          <li>Psychological safety</li>
                          <li>Autonomy</li>
                          <li>Decision making</li>
                          <li>Communication</li>
                          <li>Collaboration</li>
                          <li>Team accountability</li>
                      </ul>
                  </li> 
                  <li>
                      Chapter 8: Delivery Management
                      <ul className="ml-10 mb-5">
                          <li>Delivery management responsibility</li>
                          <li>The planning cycle rollercoaster</li>
                          <li>Building self organising teams</li>
                          <li>Measurement</li>
                          <li>Process</li>
                      </ul>
                  </li> 
                  <li>
                      Chapter 9: Stakeholder Management
                      <ul className="ml-10 mb-5">
                          <li>What is a stakeholder?</li>
                          <li>Who are your stakeholders?</li>
                          <li>Responsibility to stakeholders</li>
                          <li>Transparency</li>
                          <li>Meeting commitments</li>
                      </ul>
                  </li> 
                  <li>
                      Chapter 10: Technical Direction
                      <ul className="ml-10 mb-5">
                          <li>Responsibility for technical direction</li>
                          <li>Managing experienced engineers</li>
                          <li>Cross functional management</li>
                          <li>Influence and authority</li>
                      </ul>
                  </li> 
              </ul>
              <div className="mt-24 lg:mx-64 sm:max-w-3xl">
                  <SubscrptionForm 
                      buttonText="Notify Me"
                      formUrl="https://app.convertkit.com/forms/1943417/subscriptions" 
                  />
              </div>
          </div>
      </div>

    </section>
  </>
)
